import React from 'react'
import PropTypes from "prop-types"

import { connect } from 'react-redux'

import CriteriasSales from './CriteriasSales'
import CriteriaIndividualTarget from './CriteriasIndividualTarget'

class Criterias extends React.Component {

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <React.Fragment>
        {this.props.configurator.pointMethod === 'sales' && <CriteriasSales />}
        {this.props.configurator.pointMethod === 'individual_target' && <CriteriasSales />}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Criterias)
