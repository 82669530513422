import React from 'react'
import PropTypes from "prop-types"

import { connect } from 'react-redux'

import { Link } from 'react-foundation'

class SalesCounter extends React.Component {

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.props.validateStep()
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <React.Fragment>
        <h2>Counter</h2>
        <p>Soll es einen zusätzlichen Punkte-Counter zum Aktionszeitraum geben?<br />
        Wenn dieser Punkte-Counter aufgebraucht ist, führt dies
        ebenfalls zum Ende des Wettbewerbs.</p>

        <form>
          <div className="row">
            <div className="columns large-2 radio">
              <input name="counter" type="radio" id="counter_yes" checked={this.props.configurator.counter === true} onChange={() => this.props.changeCounter(true)} />
              <label htmlFor="counter_yes">ja</label>
            </div>
            <div className="columns large-2 radio end">
              <input name="counter" type="radio" id="counter_no" checked={this.props.configurator.counter === false} onChange={() => this.props.changeCounter(false)}/>
              <label htmlFor="counter_no">nein</label>
            </div>
          </div>
        </form>


        { this.props.configurator.counter === true &&
          <React.Fragment>
            <p>
              Welchen Betrag umfasst der Punkte-Counter? Ist das Budget aufgebraucht, führt dies ebenfalls zum Ende des Wettbewerbs.
            </p>

            <div className="row">
              <div className="columns large-6">
                <div className="flex-form">
                  <input
                    type="text"
                    defaultValue={this.props.configurator.counterPoints}
                    onChange={(e) => this.props.changeCounterPoints(e.target.value)}
                  />
                  <span className="form-info-text">Hinweis: 1 Punkt = 1€</span>
                </div>
              </div>
            </div>
          </React.Fragment>
        }

        <div className="next-button">
          <Link className="left" onClick={() => this.props.changeCriteriaTab('sales_point_options', 2)}>Zurück</Link>
          <Link onClick={() => this.props.changeCriteriaTab('sales_ranking')}>Weiter</Link>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
  changeCounter: (counter) =>
    dispatch({ type: 'CHANGE_COUNTER', counter }),
  validateStep: () =>
    dispatch({ type: 'VALIDATE_STEP_2' }),
  changeCounterPoints: (counterPoints) =>
    dispatch({ type: 'CHANGE_COUNTER_POINTS', counterPoints }),
  changeTab: (tab) =>
    dispatch({ type: 'CHANGE_TAB', tab }),
  changeCriteriaTab: (criteriaTab) =>
    dispatch({ type: 'CHANGE_CRITERIA_TAB', criteriaTab }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesCounter)
