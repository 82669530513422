import React from 'react'
import PropTypes from "prop-types"

import { connect } from 'react-redux'

import PointOptions from './PointOptions'
import Criterias    from './Criterias'
import TargetGroup  from './TargetGroup'
import Description  from './Description'
import Overview     from './Overview'

import {
  fetchSalesConfiguration,
  fetchHierarchyConfiguration,
  changeTabWithValidation
} from '../../actions/actions'

class Configurator extends React.Component {

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.props.onLoad('point_options');
    if (this.props.configurator.loading === false) {
      console.log("load hierarchy and configuration")
      this.props.onLoadConfiguration();
      this.props.onLoadHierarchy();
    }
  }

  isSelected(tabName) {
    return (this.props.configurator.tab === tabName)
  }

  tabClass(tabName) {
    return this.isSelected(tabName) ? 'active' : ''
  }

  render () {

    const tabs = [
      {name: 'Punkteoptionen',      tab: 'point_options', component: PointOptions, step: 1},
      {name: 'Kriterien',           tab: 'criterias',     component: Criterias,    step: 2},
      {name: 'Zielgruppe',          tab: 'target_group',  component: TargetGroup,  step: 3},
      {name: 'Aktionsbeschreibung', tab: 'description',   component: Description,  step: 4},
      {name: 'Überblick',           tab: 'overview',      component: Overview,     step: 5}
    ]

    return (
      <React.Fragment>

        <div className="competition-tab__wrapper">
          <ul className="competition-tab">
            {tabs.map((tab) =>
              <li
                key={ tab.tab.toString() }
                onClick={ () => this.props.changeTab(tab.tab, tab.step) }
                className={"competition-tab__item " + this.tabClass(tab.tab)}
              >
                {tab.name}
                { (tab.step <= this.props.configurator.validate_step) && (this.props.configurator["validated_step_" + tab.step] === true) &&
                  <span className="competition-validation competition-validation--complete">
                    <i className="fa fa-check" />
                  </span>
                }
                { (tab.step <= this.props.configurator.validate_step) && (this.props.configurator["validated_step_" + tab.step] === false) &&
                  <span className="competition-validation competition-validation--error">
                    <i className="fa fa-times" />
                  </span>
                }
              </li>
            )}
          </ul>
        </div>

        {tabs.map((tab) =>
          <div key={tab.tab} className="competition-content">
            {this.isSelected(tab.tab) && <tab.component /> }
          </div>
        )}

      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
  onLoad: (tab) =>
    dispatch({ type: 'CHANGE_TAB', tab }),
  changeTab: (tab, step) => {
    changeTabWithValidation(dispatch, tab, step)
  },
  onLoadConfiguration: () =>
    dispatch(fetchSalesConfiguration()),
  onLoadHierarchy: () =>
    dispatch(fetchHierarchyConfiguration())
})

export default connect(mapStateToProps, mapDispatchToProps)(Configurator)
