import React from 'react'
import PropTypes from "prop-types"

import { Link } from 'react-foundation'

import { connect } from 'react-redux'

class PointOptions extends React.Component {

  constructor(props) {
    super(props)

    this.changeTab = this.changeTab.bind(this)
    this.changePointMethod = this.changePointMethod.bind(this)
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  changeTab(tab) {
    this.props.changeTab(tab)
  }

  changePointMethod(pointMethod) {
    this.props.changePointMethod(pointMethod)
  }

  render () {
    return (
      <React.Fragment>

        <form>
          <div className="point-options-box">
            <div className="point-options-box__item">
              <input
                name="point_option"
                type="radio"
                value="sales"
                id="point_option_sales"
                checked={this.props.configurator.pointMethod === 'sales'}
                onChange={() => this.props.changePointMethod('sales')}
              />
              <label htmlFor="point_option_sales">Verkauf</label>
            </div>

            <span>oder</span>

            <div className="point-options-box__item">
              <input
                name="point_option"
                type="radio"
                value="individual_target"
                id="point_option_individual_target"
                checked={this.props.configurator.pointMethod === 'individual_target'}
                onChange={() => this.props.changePointMethod('individual_target')} />
              <label htmlFor="point_option_individual_target">Individuelle Ziele</label>
            </div>
          </div>
        </form>

        <div className="next-button">
          <Link onClick={() => this.changeTab('criterias')}>Weiter</Link>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
  changePointMethod: (pointMethod) =>
    dispatch({ type: 'CHANGE_POINT_METHOD', pointMethod }),
  changeTab: (tab) =>
    dispatch({ type: 'CHANGE_TAB', tab }),
  changeCriteriaTab: (criteriaTab) =>
    dispatch({ type: 'CHANGE_CRITERIA_TAB', criteriaTab }),
})

export default connect(mapStateToProps, mapDispatchToProps)(PointOptions)
