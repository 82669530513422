import React from 'react'
import PropTypes from "prop-types"

import { connect } from 'react-redux'

import { Link } from 'react-foundation'

import {
  changeTabWithValidation
} from '../../../actions/actions'

class SalesRanking extends React.Component {

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.props.validateStep()
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <React.Fragment>
        <h2>Aktionsranking</h2>

        <div className="form-field__wrapper">
          <p>Soll es ein Wettbewerbsranking von der Aktion geben?</p>

          <form>
            <div className="row">
              <div className="columns large-2 radio">
                <input name="ranking" type="radio" id="ranking_yes" checked={this.props.configurator.ranking === true} onChange={() => this.props.changeRanking(true)} />
                <label htmlFor="ranking_yes">ja</label>
              </div>
              <div className="columns large-2 radio end">
                <input name="ranking" type="radio" id="ranking_no" checked={this.props.configurator.ranking === false} onChange={() => this.props.changeRanking(false)}/>
                <label htmlFor="ranking_no">nein</label>
              </div>
            </div>
          </form>

          { this.props.configurator.ranking === true &&
            <React.Fragment>
              <p>
                Welche Art von Ranking möchten Sie für Ihren Wettbewerb veröffentlichen?
              </p>

              <form>
                <div className="row">
                  <div className="columns large-2 radio">
                    <input name="ranking_type" type="radio" id="ranking_overall" checked={this.props.configurator.rankingType === 'ranking_overall'} onChange={() => this.props.changeRankingType('ranking_overall')} />
                    <label htmlFor="overall_ranking">Gesamtranking</label>
                  </div>

                  { (this.props.configurator.show_region === true) &&
                    <div className="columns large-2 radio end">
                      <input name="ranking_type" type="radio" id="ranking_region" checked={this.props.configurator.rankingType === 'ranking_region'} onChange={() => this.props.changeRankingType('ranking_region')}/>
                      <label htmlFor="ranking_region">Ranking pro Region</label>
                    </div>
                  }
                </div>
              </form>
            </React.Fragment>
          }
        </div>

        <div className="next-button">
          <Link className="left" onClick={() => this.props.changeCriteriaTab('sales_counter', 2)}>Zurück</Link>
          <Link onClick={() => this.props.changeTab('target_group', 3)}>Weiter</Link>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
  validateStep: () =>
    dispatch({ type: 'VALIDATE_STEP_2' }),
  changeRanking: (ranking) =>
    dispatch({ type: 'CHANGE_RANKING', ranking }),
  changeRankingType: (rankingType) =>
    dispatch({type: 'CHANGE_RANKING_TYPE', rankingType}),
  changeTab: (tab, step) =>
    changeTabWithValidation(dispatch, tab, step),
  changeCriteriaTab: (criteriaTab) =>
    dispatch({ type: 'CHANGE_CRITERIA_TAB', criteriaTab }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesRanking)
