import React from 'react'
import PropTypes from "prop-types"

import { connect } from 'react-redux'

import { Link } from 'react-foundation'
import { SingleDatePicker } from "react-dates"

class SalesCriterias extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      focused: null,
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  focusChange = focused => {
    this.setState({ focused })
  }

  render () {
    return (
      <React.Fragment>
        <h2>Wertungskriterien und Datumsfilter</h2>

        <p>
          Auf dieser Seite definieren Sie die Kriterien für Ihren Wettbewerb.
          Sie können im ersten Schritt das gewünschte Fahrzeugmodell, welches Sie bepunkten möchten, auswählen.
          Beachten Sie hierbei, dass Sie <b>mindestens ein Modell auswählen</b>.
          Danach legen Sie bitte die weiteren zur Verfügung stehenden Wertungskriterien fest.
          Wird bei Zulassungsgrund, Engine-Type und Motor keine Auswahl getroffen, so fließen alle Wertungskriterien in die Aktion ein.
        </p>

        <p>
          Weiterhin entscheiden Sie, ob die Teilnehmer eine feste Punktezahl oder Staffelpunkte erhalten.
          Sie können eine maximale Budgetgrenze für die Punktebuchungen eintragen und entscheiden, ob ein Aktionsranking in Ihrem Wettbewerb eine Rolle spielt.
        </p>

        <div className="form-field__wrapper">
          <h3>Modell (bitte mind. ein Modell auswählen)</h3>

          <div className="row">
            {this.props.configurator.models.map((model, index) =>
              <div key={model} className="columns large-3 checkbox">
                <input
                  name="model"
                  type="checkbox"
                  value={model}
                  id={"model_" + index}
                  checked={this.props.configurator.salesModel.has(model)}
                  onChange={() => this.props.changeSalesModel(model)} />
                <label htmlFor={"model_" + index}>{model}</label>
              </div>
            )}
          </div>
        </div>

        <div className="form-field__wrapper">
          <h3>Zulassungsgrund (optional / keine Auswahl = alle Zulassungsgründe in Wertung)</h3>

          <div className="row">
            {this.props.configurator.admissionReasons.map((reason, index) =>
              <div key={reason} className="columns large-3 checkbox">
                <input
                  name="reason"
                  type="checkbox"
                  value={reason}
                  id={"reason_" + index}
                  checked={this.props.configurator.salesAdmissionReasons.has(reason)}
                  onChange={() => this.props.changeSalesAdmissionReason(reason)} />
                <label htmlFor={"reason_" + index}>{reason}</label>
              </div>
            )}
          </div>
        </div>

        <div className="form-field__wrapper">
          <h3>Engine-Type (optional / keine Auswahl = alle Engine-Types in Wertung)</h3>

          <div className="row">
            {this.props.configurator.engineTypes.map((engineType, index) =>
              <div key={engineType} className="columns large-3 checkbox">
                <input
                  name="engine"
                  type="checkbox"
                  value={engineType}
                  id={"engine_type_" + index}
                  checked={this.props.configurator.salesEngineTypes.has(engineType)}
                  onChange={() => this.props.changeSalesEngineType(engineType)} />
                <label htmlFor={"engine_type_" + index}>{engineType}</label>
              </div>
            )}
          </div>
        </div>

        <div className="form-field__wrapper">
          <h3>Motor (optional / keine Auswahl = alle Motoren in Wertung)</h3>

          <div className="row">
            {this.props.configurator.engines.map((engine, index) =>
              <div key={engine} className="columns large-3 checkbox">
                <input
                  name="engine"
                  type="checkbox"
                  value={engine}
                  id={"engine_" + index}
                  checked={this.props.configurator.salesEngines.has(engine)}
                  onChange={() => this.props.changeSalesEngine(engine)} />
                <label htmlFor={"engine_" + index}>{engine}</label>
              </div>
            )}
          </div>
        </div>

        <hr />

        <div className="form-field__wrapper form-field--contract-range margin-top-l">
          <div className="flex-form">
            <h3>Kaufvertrag</h3>

            <DatePickerCustom
              name='contractFrom'
              placeholder='von'
              date={this.props.configurator.salesContractFrom}
              setDate={this.props.changeSalesContractFrom.bind(this)}
              focusChange={this.focusChange.bind(this)}
              focused={this.state.focused} />


            <DatePickerCustom
              name='contractTo'
              placeholder='bis'
              date={this.props.configurator.salesContractTo}
              setDate={this.props.changeSalesContractTo.bind(this)}
              focusChange={this.focusChange.bind(this)}
              focused={this.state.focused} />
          </div>
        </div>

        <div className="form-field__wrapper form-field--contract-range">
          <div className="flex-form">
            <h3>Zulassungsdatum</h3>

            <DatePickerCustom
              name='admissionFrom'
              placeholder='von'
              date={this.props.configurator.salesAdmissionFrom}
              setDate={this.props.changeSalesAdmissionFrom.bind(this)}
              focusChange={this.focusChange.bind(this)}
              focused={this.state.focused} />


            <DatePickerCustom
              name='admissionTo'
              placeholder='bis'
              date={this.props.configurator.salesAdmissionTo}
              setDate={this.props.changeSalesAdmissionTo.bind(this)}
              focusChange={this.focusChange.bind(this)}
              focused={this.state.focused} />
          </div>
        </div>

        <div className="form-field__wrapper form-field--contract-range">
          <div className="flex-form">
            <h3>Hub Arrival (optional)</h3>

            <DatePickerCustom
              name='hubArrivalFrom'
              placeholder='von'
              date={this.props.configurator.salesHubArrivalFrom}
              setDate={this.props.changeSalesHubArrivalFrom.bind(this)}
              focusChange={this.focusChange.bind(this)}
              focused={this.state.focused} />


            <DatePickerCustom
              name='hubArrivalTo'
              placeholder='bis'
              date={this.props.configurator.salesHubArrivalTo}
              setDate={this.props.changeSalesHubArrivalTo.bind(this)}
              focusChange={this.focusChange.bind(this)}
              focused={this.state.focused} />
          </div>
        </div>

        <div className="next-button">
          <Link className="left" onClick={() => this.props.changeTab('point_options', 2)}>Zurück</Link>
          <Link onClick={() => this.props.changeCriteriaTab('sales_point_options')}>Weiter</Link>
        </div>
      </React.Fragment>
    )
  }
}

class DatePickerCustom extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    console.log('change date')
    return (
      <SingleDatePicker
        id={this.props.name}
        date={this.props.date}
        placeholder={this.props.placeholder}
        focused={this.props.focused === this.props.name}
        onDateChange={date => this.props.setDate(date)}
        onFocusChange={({ focused }) => this.props.focusChange(focused ? this.props.name : '')}
        displayFormat={"DD.MM.YYYY"}
        isOutsideRange={() => false}
      />
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
  onLoad: () =>
    dispatch(fetchSalesConfiguration()),
  onLoadHierarchy: () =>
    dispatch(fetchHierarchyConfiguration()),
  changeSalesModel: (salesModel) =>
    dispatch({ type: 'CHANGE_SALES_MODEL', salesModel }),
  changeSalesAdmissionReason: (admissionReason) =>
    dispatch({ type: 'CHANGE_SALES_ADMISSION_REASON', admissionReason }),
  changeSalesEngineType: (engineType) =>
    dispatch({ type: 'CHANGE_SALES_ENGINE_TYPE', engineType }),
  changeSalesEngine: (engine) =>
    dispatch({ type: 'CHANGE_SALES_ENGINE', engine }),
  changeSalesContractFrom: (salesContractFrom) =>
    dispatch({ type: 'CHANGE_SALES_CONTRACT_FROM', salesContractFrom }),
  changeSalesContractTo: (salesContractTo) =>
    dispatch({ type: 'CHANGE_SALES_CONTRACT_TO', salesContractTo }),
  changeSalesAdmissionFrom: (salesAdmissionFrom) =>
    dispatch({ type: 'CHANGE_SALES_ADMISSION_FROM', salesAdmissionFrom }),
  changeSalesAdmissionTo: (salesAdmissionTo) =>
    dispatch({ type: 'CHANGE_SALES_ADMISSION_TO', salesAdmissionTo }),
  changeSalesHubArrivalFrom: (salesHubArrivalFrom) =>
    dispatch({ type: 'CHANGE_SALES_HUB_ARRIVAL_FROM', salesHubArrivalFrom }),
  changeSalesHubArrivalTo: (salesHubArrivalTo) =>
    dispatch({ type: 'CHANGE_SALES_HUB_ARRIVAL_TO', salesHubArrivalTo }),
  changeTab: (tab) =>
    dispatch({ type: 'CHANGE_TAB', tab }),
  changeCriteriaTab: (criteriaTab) =>
    dispatch({ type: 'CHANGE_CRITERIA_TAB', criteriaTab }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesCriterias)
