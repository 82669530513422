import React from 'react'
import PropTypes from "prop-types"

import { connect } from 'react-redux'
import Text from 'react-format-text'
import { Link } from 'react-foundation'

import NumberFormat from 'react-number-format'

class Overview extends React.Component {

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    this.props.validateLastStep()
  }

  displayValueString = value => {
    if (this.props.configurator[value] === null) {
      return 'Nicht gewählt'
    } else {
      return this.props.configurator[value]
    }
  }

  displayValueArrayAll = value => {
    if (this.props.configurator[value].size === 0) {
      return 'alles ausgewählt'
    } else {
      return Array.from(this.props.configurator[value]).join("/")
    }
  }

  displayValueArray = value => {
    if (this.props.configurator[value].size === 0) {
      return 'Nicht gewählt'
    } else {
      return Array.from(this.props.configurator[value]).join("/")
    }
  }

  displayRegions = value => {
    if (this.props.configurator.selectedRegions.size === 0) {
      return 'Nicht gewählt'
    } else {
      return Array.from(
        this.props.configurator.selectedRegions).map(
          x => 'Region ' + x.identifier
        ).join(" / ")
    }
  }

  displayValueDate = (from, to) => {
    if (this.props.configurator[from] === null || this.props.configurator[to] === null) {
      return 'Nicht gewählt'
    } else {
      return this.props.configurator[from].format('DD.MM.YYYY') + "-" + this.props.configurator[to].format('DD.MM.YYYY')
    }
  }

  render() {
    return (
      <React.Fragment>
        { this.props.configurator.saved === true &&
          <React.Fragment>
            <h3>Vielen Dank!</h3>

            <p>Der Wettbewerb steht nun zur internen Freigabe bereit.</p>
            <p>Sobald diese erteilt wurde, wird Ihre Zielgruppe und Sie informiert und der Wettbewerb ist online unter "Aktuelle Wettbewerbe" zu sehen.</p>
          </React.Fragment>
        }
        { this.props.configurator.saved === false &&
          <React.Fragment>
            <p>
              An dieser Stelle haben Sie die Möglichkeit den Wettbewerb zu beschreiben.
              <br />
              <br />
              <b className="label-error">Hier sehen Sie noch einmal alle Einstellungen – bitte prüfen Sie diese und achten Sie auf die Prüfungs-Angabe im Bereich rechts oben. Danach speichern Sie bitte unten den Wettbewerb final ab.</b>
            </p>
            <div className="form-field__wrapper form-field__wrapper--large">
              <div className="row">
                <div className="columns large-12">
                  <h2>
                    Allgemeine Informationen
                    <p className="right">
                      {this.props.configurator.validated_step_5 && <span className="label-success">Angaben vollständig</span>}
                      {!this.props.configurator.validated_step_5 && <span className="label-error">Angaben nicht vollständig - bitte prüfen</span>}
                    </p>
                  </h2>
                </div>
              </div>

              <div className="competition-summary">
                <ul>
                  <li>
                    <span className="competition-summary__label">Aktionsname:</span>
                    <span className="competition-summary__value">
                      {this.props.configurator.name !== '' &&
                        this.props.configurator.name
                      }

                      {this.props.configurator.name === '' &&
                        'Nicht gewählt'
                      }
                    </span>
                  </li>
                  <li>
                    <span className="competition-summary__label">Laufzeit:</span>
                    <span className="competition-summary__value">
                      {this.props.configurator.fromDate !== null &&
                        this.props.configurator.fromDate.format('DD.MM.YYYY')
                      }

                      {this.props.configurator.fromDate === null &&
                        'Nicht gewählt'
                      }

                      -

                      {this.props.configurator.toDate !== null &&
                        this.props.configurator.toDate.format('DD.MM.YYYY')
                      }

                      {this.props.configurator.toDate === null &&
                        'Nicht gewählt'
                      }

                    </span>
                  </li>
                  <li>
                    <span className="competition-summary__label">Aktionsbeschreibung:</span>
                    <span className="competition-summary__value">
                      {this.props.configurator.description !== '' &&
                        <Text>{this.props.configurator.description}</Text>
                      }

                      {this.props.configurator.description === '' &&
                        'Nicht gewählt'
                      }
                    </span>
                  </li>
                  <li>
                    <span className="competition-summary__label">Foto:</span>
                    <span className="competition-summary__value">
                      {this.props.configurator.teaserImage !== '' &&
                        <div className="competition-summary__image-box">
                          <img src={this.props.configurator.teaserImage} alt="" />
                        </div>
                      }

                      {this.props.configurator.teaserImage === '' &&
                        'Nicht gewählt'
                      }
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="form-field__wrapper form-field__wrapper--large">
              <h2>Punkteoptionen</h2>

              <div className="competition-summary">
                <ul>
                  <li>
                    <span className="competition-summary__label">Punktevergabe:</span>
                    <span className="competition-summary__value">
                      {this.props.configurator.pointOption === null && 'Nicht gewählt'}
                      {this.props.configurator.pointOption === 'points_per_contract' && 'Punktevergabe pro Vertrag'}
                      {this.props.configurator.pointOption === 'points_interval' && 'Punkte-Staffelung'}
                    </span>
                  </li>
                  <li>
                    <span className="competition-summary__label">Punkte:</span>
                    <span className="competition-summary__value">
                      {this.props.configurator.pointOption === null && 'Nicht gewählt'}

                      {this.props.configurator.pointOption === 'points_per_contract' &&
                        <div>{this.props.configurator.pointsPerContract} Punkte</div>
                      }

                      {this.props.configurator.pointOption === 'points_interval' &&
                        <ul>
                          {this.props.configurator.pointsRange.map((range, index) =>
                            <li key={"pr_" + index}>
                              von {range.from} Verträgen bis {range.to} Verträgen {range.points} Punkte
                            </li>
                          )}
                        </ul>
                      }
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="form-field__wrapper form-field__wrapper--large">
              <h2>Wertungskriterien</h2>

              <div className="competition-summary">
                <ul>
                  <li>
                    <span className="competition-summary__label">Modell:</span>
                    <span className="competition-summary__value">
                      {this.displayValueArray('salesModel')}
                    </span>
                  </li>
                  <li>
                    <span className="competition-summary__label">Zulassungsgrund:</span>
                    <span className="competition-summary__value">
                      {this.displayValueArrayAll('salesAdmissionReasons')}
                    </span>
                  </li>
                  <li>
                    <span className="competition-summary__label">Engine-Type:</span>
                    <span className="competition-summary__value">
                      {this.displayValueArrayAll('salesEngineTypes')}
                    </span>
                  </li>
                  <li>
                    <span className="competition-summary__label">Motor:</span>
                    <span className="competition-summary__value">
                      {this.displayValueArrayAll('salesEngines')}
                    </span>
                  </li>
                  <li>
                    <span className="competition-summary__label">Kaufvertrag:</span>
                    <span className="competition-summary__value">
                      {this.displayValueDate('salesContractFrom', 'salesContractTo')}
                    </span>
                  </li>
                  <li>
                    <span className="competition-summary__label">Zulassungsdatum:</span>
                    <span className="competition-summary__value">
                      {this.displayValueDate('salesAdmissionFrom', 'salesAdmissionTo')}
                    </span>
                  </li>
                  <li>
                    <span className="competition-summary__label">Hub Arrival:</span>
                    <span className="competition-summary__value">
                      {this.displayValueDate('salesHubArrivalFrom', 'salesHubArrivalTo')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="form-field__wrapper form-field__wrapper--large">
              <h2>PUNKTECOUNTER</h2>

              <div className="competition-summary">
                {this.props.configurator.counter === false &&
                  <span className="competition-summary__value">
                    Nicht gewählt
                  </span>
                }

                {this.props.configurator.counter === true &&
                  <ul>
                    <li>
                      <span className="competition-summary__label">Counterhöhe:</span>
                      <span className="competition-summary__value">
                        <NumberFormat value={this.props.configurator.counterPoints} displayType={'text'} thousandSeparator="." decimalSeparator={","} /> Punkte
                      </span>
                    </li>
                  </ul>
                }
              </div>
            </div>

            <div className="form-field__wrapper form-field__wrapper--large">
              <h2>AKTIONSRANKING</h2>

              <div className="competition-summary">
                {this.props.configurator.ranking === false &&
                  <span className="competition-summary__value">
                    Nicht gewählt
                  </span>
                }

                {this.props.configurator.ranking === true &&
                  <ul>
                    <li>
                      <span className="competition-summary__label">Rankinganzeige:</span>
                      <span className="competition-summary__value">
                        {this.props.configurator.rankingType === 'ranking_overall' && 'Gesamtranking'}
                        {this.props.configurator.rankingType === 'ranking_region' && 'Ranking pro Region'}
                      </span>
                    </li>
                  </ul>
                }
              </div>
            </div>

            <div className="form-field__wrapper form-field__wrapper--large">
              <h2>ZIELGRUPPE</h2>

              <div className="competition-summary">
                <ul>
                  {this.props.configurator.show_region &&
                    <li>
                      <span className="competition-summary__label">Region:</span>
                      <span className="competition-summary__value">
                        {this.displayRegions('selectedRegions')}
                      </span>
                    </li>
                  }
                  <li>
                    <span className="competition-summary__label">Lexus Forum:</span>
                    <span className="competition-summary__value">
                      <div className="row reset-margin employee-list">
                        {Array.from(this.props.configurator.selectedCompanies).map((company, index) =>
                          <div key={"sc_" + index} className="columns large-4">
                            <div className="employee-list__item">
                              <span className="employee-list__name">{company.company_name}</span>
                              <span className="employee-list__number">({company.company_reference})</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </span>
                  </li>
                  <li>
                    <span className="competition-summary__label">Teilnehmer:</span>
                    <span className="competition-summary__value">
                      <div className="row reset-margin employee-list">
                        {Array.from(this.props.configurator.selectedEmployees).map((employee, index) =>
                          <div key={"se_" + index} className="columns large-4">
                            <div className="employee-list__item">
                              <span className="employee-list__name">{employee.last_name}, {employee.first_name} ({employee.company_reference})</span>
                              <span className="employee-list__number">({employee.employee_number} / {employee.role})</span>

                              {this.props.configurator.pointMethod === 'individual_target' &&
                                <div className="label-with-input">
                                  <span>{employee.points} Zulassungen</span>
                                </div>
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <Link
              onClick={() => this.props.configurator.validated_step_5 ? this.props.saveUserCompetition() : null}
              disabled={this.props.configurator.validated_step_5 ? null : 'disabled'}>Speichern</Link>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
  saveUserCompetition: () =>
    dispatch({ type: 'SAVE_USER_COMPETITION' }),
  validateLastStep: () => {
    dispatch({ type: 'SET_VALIDATION_STEP', step: 6 })
    dispatch({ type: 'VALIDATE_STEP', step: 6 })
    dispatch({ type: 'VALIDATE_STEP_1' })
    dispatch({ type: 'VALIDATE_STEP_2' })
    dispatch({ type: 'VALIDATE_STEP_3' })
    dispatch({ type: 'VALIDATE_STEP_4' })
    dispatch({ type: 'VALIDATE_STEP_5' })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
