/*
 * action types
 */
 
export const CHANGE_TAB = 'CHANGE_TAB'
export const CHANGE_CRITERIA_TAB = 'CHANGE_CRITERIA_TAB'
export const CHANGE_POINT_METHOD = 'CHANGE_POINT_METHOD'

// Sales Criterias
export const CHANGE_SALES_MODEL             = 'CHANGE_SALES_MODEL'
export const CHANGE_SALES_ADMISSION_REASON  = 'CHANGE_SALES_ADMISSION_REASON'
export const CHANGE_SALES_ENGINE_TYPE       = 'CHANGE_SALES_ENGINE_TYPE'
export const CHANGE_SALES_ENGINE            = 'CHANGE_SALES_ENGINE'
export const CHANGE_SALES_CONTRACT_FROM     = 'CHANGE_SALES_CONTRACT_FROM'
export const CHANGE_SALES_CONTRACT_TO       = 'CHANGE_SALES_CONTRACT_TO'
export const CHANGE_SALES_ADMISSION_FROM    = 'CHANGE_SALES_ADMISSION_FROM'
export const CHANGE_SALES_ADMISSION_TO      = 'CHANGE_SALES_ADMISSION_TO'
export const CHANGE_SALES_HUB_ARRIVAL_FROM  = 'CHANGE_SALES_HUB_ARRIVAL_FROM'
export const CHANGE_SALES_HUB_ARRIVAL_TO    = 'CHANGE_SALES_HUB_ARRIVAL_TO'

export const TOGGLE_SELECTED_REGIONS = 'TOGGLE_SELECTED_REGIONS'
export const TOGGLE_SELECTED_COMPANIES = 'TOGGLE_SELECTED_COMPANIES'
export const TOGGLE_SELECTED_EMPLOYEES = 'TOGGLE_SELECTED_EMPLOYEES'
export const CHANGE_EMPLOYEE_TARGET = 'CHANGE_EMPLOYEE_TARGET'

export const CHANGE_POINT_OPTION = 'CHANGE_POINT_OPTION'
export const CHANGE_POINTS_PER_CONTRACT = 'CHANGE_POINTS_PER_CONTRACT'
export const UPDATE_POINTS_RANGE = 'UPDATE_POINTS_RANGE'
export const ADD_POINTS_RANGE    = 'ADD_POINTS_RANGE'

export const CHANGE_COUNTER         = 'CHANGE_COUNTER'
export const CHANGE_COUNTER_POINTS  = 'CHANGE_COUNTER_POINTS'

export const CHANGE_RANKING = 'CHANGE_RANKING'
export const CHANGE_RANKING_TYPE = 'CHANGE_RANKING_TYPE'

export const CHANGE_FROM_DATE    = 'CHANGE_FROM_DATE'
export const CHANGE_TO_DATE      = 'CHANGE_TO_DATE'
export const CHANGE_NAME         = 'CHANGE_NAME'
export const CHANGE_DESCRIPTION  = 'CHANGE_DESCRIPTION'
export const CHANGE_TEASER_IMAGE = 'CHANGE_TEASER_IMAGE'

export const FETCH_SALES_CONFIGURATION_BEGIN   = 'FETCH_SALES_CONFIGURATION_BEGIN'
export const FETCH_SALES_CONFIGURATION_SUCCESS = 'FETCH_SALES_CONFIGURATION_SUCCESS'
export const FETCH_SALES_CONFIGURATION_FAILURE = 'FETCH_SALES_CONFIGURATION_FAILURE'

export const FETCH_HIERARCHY_CONFIGURATION_BEGIN   = 'FETCH_HIERARCHY_CONFIGURATION_BEGIN'
export const FETCH_HIERARCHY_CONFIGURATION_SUCCESS = 'FETCH_HIERARCHY_CONFIGURATION_SUCCESS'
export const FETCH_HIERARCHY_CONFIGURATION_FAILURE = 'FETCH_HIERARCHY_CONFIGURATION_FAILURE'

export const SAVE_USER_COMPETITION = 'SAVE_USER_COMPETITION'

export const VALIDATE_COMPETITION = 'VALIDATE_COMPETITION'
export const SET_VALIDATION_STEP  = 'SET_VALIDATION_STEP'
export const VALIDATE_STEP = 'VALIDATE_STEP'

export const VALIDATE_STEP_1 = 'VALIDATE_STEP_1'
export const VALIDATE_STEP_2 = 'VALIDATE_STEP_2'
export const VALIDATE_STEP_3 = 'VALIDATE_STEP_3'
export const VALIDATE_STEP_4 = 'VALIDATE_STEP_4'
export const VALIDATE_STEP_5 = 'VALIDATE_STEP_5'

/*
 * other constants
 */
 
// export const VisibilityFilters = {
//   SHOW_ALL: 'SHOW_ALL',
//   SHOW_COMPLETED: 'SHOW_COMPLETED',
//   SHOW_ACTIVE: 'SHOW_ACTIVE'
// }
 
/*
 * action creators
 */

 export function changeTabWithValidation(dispatch, tab, step) {
   dispatch({ type: 'CHANGE_TAB', tab }),
   dispatch({ type: 'SET_VALIDATION_STEP', step })
   dispatch({ type: 'VALIDATE_STEP', step })

   let validationSteps = _.range(1, step)

   _.forEach(validationSteps, step => {
     switch (step) {
       case 1:
         dispatch({ type: 'VALIDATE_STEP_1' })
         break
       case 2:
         dispatch({ type: 'VALIDATE_STEP_2' })
         break
       case 3:
         dispatch({ type: 'VALIDATE_STEP_3' })
         break
       case 4:
         dispatch({ type: 'VALIDATE_STEP_4' })
         break
       case 5:
         dispatch({ type: 'VALIDATE_STEP_5' })
         break
       default:
         console.log("not validation step found")
     }
   })
 }

 export function validateStep2(state) {
  let criterias = [
   (state.salesModel.size >= 1),
   (state.salesContractFrom !== null),
   (state.salesContractTo !== null),
   (state.salesAdmissionFrom !== null),
   (state.salesAdmissionTo !== null),
  ]
  let criterialResult = _.every(criterias, Boolean)

  let points = []

  console.log(state.pointOption)

  if (state.pointOption === 'points_per_contract') {
    points = [true]
  } else if (state.pointOption === 'points_interval') {
    points = [true]
  } else {
    points = [false]
  }

  let pointsResult = _.every(points, Boolean)

  let counter = []

  if (state.counter) {
    if (state.counterPoints > 0) {
      counter = [true]
    } else {
      counter = [false]
    }
  } else {
    counter = [true]
  }

  let counterResult = _.every(counter, Boolean)

  let result = _.every([
    criterialResult,
    pointsResult,
    counterResult
  ], Boolean)

   return {
     ...state,
     validated_step_2: result,
     validated_step_sales_criterias: criterialResult,
     validated_step_sales_point_options: pointsResult,
     validated_step_sales_counter: counterResult
   }
 }

export function validateStep3(state) {
  console.log(state.selectedEmployees.size)

  let validTargetValues = true

  if (state.pointMethod === 'individual_target') {
    validTargetValues =  _.every(Array.from(state.selectedEmployees), (employee) => {
      return (parseInt(employee.points) >= 0)
    })
  }

  console.log("validTargetValues: " + validTargetValues)

  if (state.selectedEmployees.size > 0 && validTargetValues) {
    return {...state, validated_step_3: true}
  } else {
    return {...state, validated_step_3: false}
  }
}

export function validateStep4(state) {
  let competitionDescription = [
    (state.fromDate != null),
    (state.toDate != null),
    (state.name.length > 0),
    (state.teaserImage.length > 0),
    (state.description.length > 0),
  ]

  let competitionDescriptionResult = _.every(competitionDescription, Boolean)

  return {...state, validated_step_4: competitionDescriptionResult}
}

export function validateStep5(state) {
  let validationSteps = [
    state.validated_step_1,
    state.validated_step_2,
    state.validated_step_3,
    state.validated_step_4,
  ]

  let validationResult = _.every(validationSteps, Boolean)

  return {...state, validated_step_5: validationResult}
}

export function changeTab(tabId) {
  return { type: CHANGE_TAB, tabId }
}

export function changePointMethod(pointMethod) {
  return { type: CHANGE_POINT_METHOD, pointMethod }
}

export const fetchSalesConfigurationBegin = () => ({
  type: FETCH_SALES_CONFIGURATION_BEGIN
});

export const fetchSalesConfigurationSuccess = configuration => ({
  type: FETCH_SALES_CONFIGURATION_SUCCESS,
  payload: { configuration }
});

export const fetchSalesConfigurationError = error => ({
  type: FETCH_SALES_CONFIGURATION_FAILURE,
  payload: { error }
});

export function fetchSalesConfiguration() {
  return dispatch => {
    dispatch(fetchSalesConfigurationBegin());
    return fetch("/api/v1/user_competitions_configuration/sales_criterias.json", {credentials: 'same-origin'})
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchSalesConfigurationSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchSalesConfigurationError(error)));
  }
}

export const fetchHierarchyConfigurationBegin = () => ({
  type: FETCH_HIERARCHY_CONFIGURATION_BEGIN
});

export const fetchHierarchyConfigurationSuccess = configuration => ({
  type: FETCH_HIERARCHY_CONFIGURATION_SUCCESS,
  payload: { configuration }
});

export const fetchHierarchyConfigurationError = error => ({
  type: FETCH_HIERARCHY_CONFIGURATION_FAILURE,
  payload: { error }
});

export function fetchHierarchyConfiguration() {
  return dispatch => {
    dispatch(fetchHierarchyConfigurationBegin());
    return fetch("/api/v1/users/competition_configurator.json", {credentials: 'same-origin'})
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchHierarchyConfigurationSuccess(json));
        return json;
      })
      .catch(error => dispatch(fetchHierarchyConfigurationError(error)));
  }
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
