import React from 'react'
import PropTypes from "prop-types"

import { connect } from 'react-redux'

import { Link } from 'react-foundation'

import {
  changeTabWithValidation
} from '../../actions/actions'

class TargetGroup extends React.Component {

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <React.Fragment>
        <p>
          Wählen Sie die Zielgruppe Ihres Wettbewerbs aus. Sie können die Zielgruppe ganz individuell zusammenstellen. Die Teilnahme gesamter Lexus Foren als auch die Beteiligung einzelner Geschäftsführer oder Verkäufer ist möglich.
        </p>

        { this.props.configurator.show_region &&
          <div className="form-field__wrapper form-field__wrapper--large">
            <h2>Region ({this.props.configurator.selectedRegions.size})</h2>

            <div className="flex-form flex-form--vertical">
              {this.props.configurator.regions.map((region, index) =>
                <div key={'region_' + region.id} className="columns large-6 checkbox">
                  <input
                    name="reason"
                    type="checkbox"
                    value={region.id}
                    id={"region_" + index}
                    checked={this.props.configurator.selectedRegions.has(region)}
                    onChange={() => this.props.toggleSelectedRegions(region, !this.props.configurator.selectedRegions.has(region))} />
                  <label htmlFor={"region_" + index}>Region {region.identifier}</label>
                </div>
              )}
            </div>
          </div>
        }

        <div className="form-field__wrapper form-field__wrapper--large">
          <h2>Lexus Forum ({this.props.configurator.selectedCompanies.size})</h2>

          <div className="row employee-list">
            {this.props.configurator.companies.map((company, index) =>
              <div key={'company_' + company.id} className="columns large-6 checkbox">
                <input
                  name="reason"
                  type="checkbox"
                  value={company.id}
                  id={"company_" + index}
                  checked={this.props.configurator.selectedCompanies.has(company)}
                  onChange={() => this.props.toggleSelectedCompanies(company, !this.props.configurator.selectedCompanies.has(company))} />
                <label htmlFor={"company_" + index}>
                  <span className="employee-list__name">{company.company_name}</span>
                  <span className="employee-list__number">({company.company_reference})</span>
                </label>
              </div>
            )}
          </div>
        </div>

        <div className="form-field__wrapper form-field__wrapper--large">
          <h2>Teilnehmer ({this.props.configurator.selectedEmployees.size})</h2>

          <div className={(this.props.configurator.pointMethod === 'sales' ? 'row employee-list' : 'row employee-list employee-list--individual')}>
            {this.props.configurator.employees.map((employee, index) =>
              <div key={'employee_' + employee.id} className="columns large-4 checkbox">
                <input
                  name="reason"
                  type="checkbox"
                  value={employee.id}
                  id={"employee_" + index}
                  checked={this.props.configurator.selectedEmployees.has(employee)}
                  onChange={() => this.props.toggleSelectedEmployees(employee, !this.props.configurator.selectedEmployees.has(employee))} />
                <label htmlFor={"employee_" + index}>
                  <span className="employee-list__name">{employee.last_name}, {employee.first_name} ({employee.company_reference})</span>
                  <span className="employee-list__number">({employee.employee_number} / {employee.role})</span>

                  { this.props.configurator.pointMethod === 'individual_target' && this.props.configurator.selectedEmployees.has(employee) &&
                    <div className="label-with-input">
                      <input
                        type="text"
                        defaultValue={employee.points}
                        onChange={ (e) => this.props.changeEmployeeTarget(employee, e.target.value) }
                      />
                      <span>Anzahl Zulassungen</span>
                    </div>
                  }
                </label>
              </div>
            )}
          </div>
        </div>

        <div className="next-button">
          <Link className="left" onClick={() => this.props.changeTab('criterias', 4)}>Zurück</Link>
          <Link onClick={() => this.props.changeTab('description', 4)}>Weiter</Link>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
  toggleSelectedRegions: (region, checked) =>
    dispatch({ type: 'TOGGLE_SELECTED_REGIONS', region, checked}),
  toggleSelectedCompanies: (company, checked) =>
    dispatch({ type: 'TOGGLE_SELECTED_COMPANIES', company, checked}),
  toggleSelectedEmployees: (employee, checked) =>
    dispatch({ type: 'TOGGLE_SELECTED_EMPLOYEES', employee, checked}),
  changeEmployeeTarget: (employee, points) =>
    dispatch({ type: 'CHANGE_EMPLOYEE_TARGET', employee, points}),
  changeTab: (tab, step) =>
    changeTabWithValidation(dispatch, tab, step)
})

export default connect(mapStateToProps, mapDispatchToProps)(TargetGroup)
