import React from 'react'
import PropTypes from "prop-types"

import { connect } from 'react-redux'

import { Link } from 'react-foundation'

class SalesPointOptions extends React.Component {

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.props.validateStep()
    window.scrollTo(0, 0)
  }

  changeRangeValue(index, attr, event) {
    this.props.updatePointsRange(index, {[attr]: event.target.value})
  }

  render () {
    return (
      <React.Fragment>
        <h2>Punktevergabe</h2>

        {this.props.configurator.pointMethod === 'sales' &&
          <p>Sie können eine feste Punktzahl pro verkauftem Fahrzeug eingeben oder eine Staffelung der Punkte festlegen, je nach Anzahl der verkauften Fahrzeuge.
            <br/>
            <br/>
            <b>Beispiel für eine Punkte-Staffelung:</b><br />
            Ab 1 bis 3 (verkauften Fahrzeugen) = 100 Punkte<br />
            Ab 4 bis 5 (verkauften Fahrzeugen) = 200 Punkte
          </p>
        }

        {this.props.configurator.pointMethod === 'individual_target' &&
          <p>
            Sie können eine feste Punktzahl für die Zielerreichung eingeben oder eine Staffelung der Punkte festlegen.
            <br/>
            <br/>
            <b>Beispiel für eine Punkte-Staffelung:</b><br/>
            Zielvorgabe = 10 Zulassungen = 100% = 200 Punkte
          </p>
        }

        <div className="form-field__wrapper">
          <div className="radio">
            <input
              name="points_award"
              type="radio"
              id="points_per_contract"
              checked={this.props.configurator.pointOption === 'points_per_contract'}
              onChange={() => this.props.selectPointOption('points_per_contract')}
            />
            <label htmlFor="points_per_contract">
              {this.props.configurator.pointMethod === 'sales' &&
                'Punkte pro Vertrag'
              }
              {this.props.configurator.pointMethod === 'individual_target' &&
                'Punkte bei 100% Zielerreichung'
              }
            </label>
          </div>

          <div className="row">
            <div className="columns large-6">
              <div className="flex-form">
                <input
                  type="text"
                  defaultValue={this.props.configurator.pointsPerContract}
                  onChange={(e) => this.props.changePointsPerContract(e.target.value)}
                />
                <span className="form-info-text">Hinweis: 1 Punkt = 1€</span>
              </div>
            </div>
          </div>
        </div>

        <div className="form-field__wrapper">
          <div className="radio">
            <input
              name="points_award"
              type="radio"
              id="points_graduation"
              checked={this.props.configurator.pointOption === 'points_interval'}
              onChange={() => this.props.selectPointOption('points_interval')}
            />
            <label htmlFor="points_graduation">
              Punkte-Staffelung
            </label>
          </div>

          <div className="points-range-form">
            <div className="points-range-form__column">
              {this.props.configurator.pointsRange.map((range, index) =>
                <div key={'pointsRange' + index}>
                  <div className="flex-form">
                    <input type="text" placeholder={ this.props.configurator.pointMethod === 'sales' ? 'von' : 'von %' } value={range.from} onChange={(e) => this.changeRangeValue(index, 'from', e)} />
                    <input type="text" placeholder={ this.props.configurator.pointMethod === 'sales' ? 'bis' : 'bis %' } onChange={(e) => this.changeRangeValue(index, 'to', e)}  />
                    <span>=</span>
                    <input type="text" placeholder="Punkte" onChange={(e) => this.changeRangeValue(index, 'points', e)} />
                  </div>
                </div>
              )}
            </div>

            <div className="points-range-form__button">
              <button className="button button--ghost add-new-points-range" onClick={() => this.props.addPointsRange()}>
                Weitere Staffelung hinzufügen
              </button>
            </div>
          </div>
        </div>

        <div className="next-button">
          <Link className="left" onClick={() => this.props.changeCriteriaTab('sales_criterias', 2)}>Zurück</Link>
          <Link onClick={() => this.props.changeCriteriaTab('sales_counter')}>Weiter</Link>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
  validateStep: () =>
    dispatch({ type: 'VALIDATE_STEP_2' }),
  selectPointOption: (pointOption) =>
    dispatch({ type: 'CHANGE_POINT_OPTION', pointOption }),
  changePointsPerContract: (pointsPerContract) =>
    dispatch({ type: 'CHANGE_POINTS_PER_CONTRACT', pointsPerContract}),
  updatePointsRange: (index, attr) =>
    dispatch({ type: 'UPDATE_POINTS_RANGE', index, attr}),
  addPointsRange: () =>
    dispatch({ type: 'ADD_POINTS_RANGE' }),
  changeTab: (tab) =>
    dispatch({ type: 'CHANGE_TAB', tab }),
  changeCriteriaTab: (criteriaTab) =>
    dispatch({ type: 'CHANGE_CRITERIA_TAB', criteriaTab }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesPointOptions)
