import React from 'react'
import PropTypes from "prop-types"

import { connect } from 'react-redux'

class CriteriasIndividualTarget extends React.Component {

  constructor(props) {
    super(props)
  }

  render () {
    return (
      <React.Fragment>
        <h1>Kriterien für Individuelle Ziele</h1>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CriteriasIndividualTarget)
