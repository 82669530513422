import React from 'react'
import PropTypes from "prop-types"

import {
  connect
} from 'react-redux'

import {
  Link
} from 'react-foundation'
import {
  SingleDatePicker
} from "react-dates"

import {
  changeTabWithValidation
} from '../../actions/actions'

class Description extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      focused: null,
      images: [
        "https://bumg.s3.amazonaws.com/toyota/lexus%2F00-Relaunch2018%2F05_SE-Teaser%2F1803_BOL_SE_01_Teaser_CT.jpg",
        "https://bumg.s3.amazonaws.com/toyota/lexus%2F00-Relaunch2018%2F05_SE-Teaser%2F1803_BOL_SE_02_Teaser_GS.jpg",
        "https://bumg.s3.amazonaws.com/toyota/lexus%2F00-Relaunch2018%2F05_SE-Teaser%2F1803_BOL_SE_03_Teaser_alle.jpg",
        "https://bumg.s3.amazonaws.com/toyota/lexus%2F00-Relaunch2018%2F05_SE-Teaser%2F1803_BOL_SE_04_Teaser_IS.jpg",
        "https://bumg.s3.amazonaws.com/toyota/lexus%2F00-Relaunch2018%2F05_SE-Teaser%2F1803_BOL_SE_05_Teaser_LC.jpg",
        "https://bumg.s3.amazonaws.com/toyota/lexus%2F00-Relaunch2018%2F05_SE-Teaser%2F1803_BOL_SE_06_Teaser_LS.jpg",
        "https://bumg.s3.amazonaws.com/toyota/lexus%2F00-Relaunch2018%2F05_SE-Teaser%2F1803_BOL_SE_07_Teaser_NX.jpg",
        "https://bumg.s3.amazonaws.com/toyota/lexus%2F00-Relaunch2018%2F05_SE-Teaser%2F1803_BOL_SE_08_Teaser_RC.jpg",
        "https://bumg.s3.amazonaws.com/toyota/lexus%2F00-Relaunch2018%2F05_SE-Teaser%2F1803_BOL_SE_09_Teaser_RX.jpg",
        "https://bumg.s3.amazonaws.com/toyota/lexus%2F00-Relaunch2018%2F05_SE-Teaser%2F1906_BOL_SE_10_Teaser_ES.jpg",
        "https://bumg.s3.amazonaws.com/toyota/lexus%2F00-Relaunch2018%2F05_SE-Teaser%2F1906_BOL_SE_11_Teaser_UX.jpg",
      ]
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  focusChange = focused => {
    this.setState({
      focused
    })
  }

  render() {
    return ( <
      React.Fragment >
      <
      p >
      An dieser Stelle haben Sie die Möglichkeit den Wettbewerb zu beschreiben.Sie können den Aktionszeitraum einstellen, dem Wettbewerb einen Namen geben und aus einer hinterlegten Bildergalerie ein passendes Wettbewerbsmotiv auswählen. <
      br / >
      Abschließend können Sie einen Wettbewerbstext verfassen, der als Text unter dem Wettbewerbsbild für alle an der Aktion teilnehmenden Verkäufer sichtbar ist. <
      /p>

      <
      div className = "form-field__wrapper form-field__wrapper--large" >
      <
      h2 > Aktionszeitraum < /h2>

      <
      div className = "flex-form" >
      <
      DatePickerCustom name = 'dateFrom'
      placeholder = 'von'
      date = {
        this.props.configurator.fromDate
      }
      setDate = {
        this.props.changeDateFrom.bind(this)
      }
      focusChange = {
        this.focusChange.bind(this)
      }
      focused = {
        this.state.focused
      }
      />


      <
      DatePickerCustom name = 'dateTo'
      placeholder = 'bis'
      date = {
        this.props.configurator.toDate
      }
      setDate = {
        this.props.changeDateTo.bind(this)
      }
      focusChange = {
        this.focusChange.bind(this)
      }
      focused = {
        this.state.focused
      }
      /> < /
      div > <
      /div>

      <
      div className = "form-field__wrapper form-field__wrapper--large" >
      <
      h2 > Wettbewerbsname & Foto - Upload < /h2>

      <
      div className = "row" >
      <
      div className = "columns large-6" >
      <
      div className = "flex-form" >
      <
      label > Wettbewerbsname: < /label> <
      input type = "text"
      defaultValue = {
        this.props.configurator.name
      }
      onChange = {
        (e) => this.props.changeName(e.target.value)
      }
      className = "double-sized-input" /
      >
      <
      span className = "form-info-text" > Max 30 Zeichen < /span> < /
      div > <
      /div> < /
      div >

      <
      div className = "choose-image__wrapper" >
      <
      div className = "row" >
      <
      div className = "columns" >
      <
      label > Foto auswählen < /label> < /
      div > <
      /div>


      <
      div className = "row choose-image__list" > {
        this.state.images.map((image, index) =>
          <
          div key = {
            'image_' + index
          }
          className = "columns large-4" >
          <
          input name = "competition_image"
          id = {
            "competition_image_" + index
          }
          type = "radio"
          onChange = {
            (e) => this.props.changeTeaserImage(image)
          }
          /> <
          label htmlFor = {
            "competition_image_" + index
          } >
          <
          img src = {
            image
          }
          alt = "" / >
          <
          /label> < /
          div >
        )
      } <
      /div> < /
      div > <
      /div>

      <
      div className = "form-field__wrapper form-field__wrapper--large" >
      <
      h2 > Aktionsbeschreibung < /h2>

      <
      textarea type = "text"
      defaultValue = {
        this.props.configurator.description
      }
      onChange = {
        (e) => this.props.changeDescription(e.target.value)
      }
      /> < /
      div >

      <
      div className = "next-button" >
      <
      Link className = "left"
      onClick = {
        () => this.props.changeTab('target_group', 6)
      } > Zurück < /Link> <
      Link onClick = {
        () => this.props.changeTab('overview', 6)
      } > Weiter < /Link> < /
      div > <
      /React.Fragment>
    )
  }
}

class DatePickerCustom extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    console.log('change date')
    return ( <
      SingleDatePicker id = {
        this.props.name
      }
      date = {
        this.props.date
      }
      placeholder = {
        this.props.placeholder
      }
      focused = {
        this.props.focused === this.props.name
      }
      onDateChange = {
        date => this.props.setDate(date)
      }
      onFocusChange = {
        ({
          focused
        }) => this.props.focusChange(focused ? this.props.name : '')
      }
      displayFormat = {
        "DD.MM.YYYY"
      }
      isOutsideRange = {
        () => false
      }
      />
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
  changeDateFrom: (fromDate) =>
    dispatch({
      type: 'CHANGE_FROM_DATE',
      fromDate
    }),
  changeDateTo: (toDate) =>
    dispatch({
      type: 'CHANGE_TO_DATE',
      toDate
    }),
  changeName: (name) =>
    dispatch({
      type: 'CHANGE_NAME',
      name
    }),
  changeDescription: (description) =>
    dispatch({
      type: 'CHANGE_DESCRIPTION',
      description
    }),
  changeTeaserImage: (teaserImage) =>
    dispatch({
      type: 'CHANGE_TEASER_IMAGE',
      teaserImage
    }),
  changeTab: (tab, step) =>
    changeTabWithValidation(dispatch, tab, step)
})

export default connect(mapStateToProps, mapDispatchToProps)(Description)
