import React from 'react'
import PropTypes from "prop-types"

import { connect } from 'react-redux'

import SalesPointOptions from './criterias/SalesPointOptions'
import SalesCriterias from './criterias/SalesCriterias'
import SalesCounter from './criterias/SalesCounter'
import SalesRanking from './criterias/SalesRanking'

import {
  changeTabWithValidation
} from '../../actions/actions'

class CriteriasSales extends React.Component {

  constructor(props) {
    super(props)
  }

  isSelected(tabName) {
    return (this.props.configurator.criteriaTab === tabName)
  }

  tabClass(tabName) {
    return this.isSelected(tabName) ? 'active' : ''
  }

  render () {
    const tabs = [
      {name: 'Wertungskriterien und Datumsfilter',  tab: 'sales_criterias',     component: SalesCriterias},
      {name: 'Punktevergabe',                       tab: 'sales_point_options', component: SalesPointOptions},
      {name: 'Counter',                             tab: 'sales_counter',       component: SalesCounter},
      {name: 'Aktionsranking',                      tab: 'sales_ranking',       component: SalesRanking},
    ]

    return (
      <React.Fragment>
        <ul className="competition-tab competition-tab--sub">
          {tabs.map((tab) =>
            <li
              key={tab.tab.toString()}
              onClick={() => this.props.changeCriteriaTab(tab.tab)}
              className={
                "competition-tab__item " +
                this.tabClass(tab.tab) +
                " " +
                " " +
                ((this.props.configurator["validated_step_" + tab.tab] === false) ? "competition-tab--error" : "")
              }
            >
              {tab.name}
            </li>
          )}
        </ul>

        {tabs.map((tab) =>
          <div key={tab.tab} className={"competition-content--sub " + this.tabClass(tab.tab)}>
            {this.isSelected(tab.tab) && <tab.component /> }
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  configurator: state.configurator
})

const mapDispatchToProps = dispatch => ({
  changeTab: (tab, step) =>
    changeTabWithValidation(tab, step),
  changeCriteriaTab: (criteriaTab) =>
    dispatch({ type: 'CHANGE_CRITERIA_TAB', criteriaTab }),
})

export default connect(mapStateToProps, mapDispatchToProps)(CriteriasSales)
