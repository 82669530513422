import React from 'react'
import PropTypes from "prop-types"

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from '../reducers/rootReducer'

import Configurator from './UserCompetitionComponent/Configurator'

export const store = createStore(rootReducer, applyMiddleware(thunk))

class UserCompetitionComponent extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <Configurator />
      </Provider>
    )
  }
}

export default UserCompetitionComponent
