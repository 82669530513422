import {
  CHANGE_TAB,
  CHANGE_CRITERIA_TAB,
  CHANGE_POINT_METHOD,
  CHANGE_SALES_MODEL,
  CHANGE_SALES_ADMISSION_REASON,
  CHANGE_SALES_ENGINE_TYPE,
  CHANGE_SALES_ENGINE,
  CHANGE_POINT_OPTION,
  CHANGE_POINTS_PER_CONTRACT,
  UPDATE_POINTS_RANGE,
  ADD_POINTS_RANGE,
  CHANGE_COUNTER,
  CHANGE_COUNTER_POINTS,
  CHANGE_RANKING,
  CHANGE_RANKING_TYPE,
  CHANGE_FROM_DATE,
  CHANGE_TO_DATE,
  CHANGE_NAME,
  CHANGE_DESCRIPTION,
  CHANGE_TEASER_IMAGE,
  CHANGE_SALES_CONTRACT_FROM,
  CHANGE_SALES_CONTRACT_TO,
  CHANGE_SALES_ADMISSION_FROM,
  CHANGE_SALES_ADMISSION_TO,
  CHANGE_SALES_HUB_ARRIVAL_FROM,
  CHANGE_SALES_HUB_ARRIVAL_TO,
  TOGGLE_SELECTED_REGIONS,
  TOGGLE_SELECTED_COMPANIES,
  TOGGLE_SELECTED_EMPLOYEES,
  CHANGE_EMPLOYEE_TARGET,
  FETCH_SALES_CONFIGURATION_BEGIN,
  FETCH_SALES_CONFIGURATION_SUCCESS,
  FETCH_SALES_CONFIGURATION_FAILURE,
  FETCH_HIERARCHY_CONFIGURATION_BEGIN,
  FETCH_HIERARCHY_CONFIGURATION_SUCCESS,
  FETCH_HIERARCHY_CONFIGURATION_FAILURE,
  SAVE_USER_COMPETITION,
  VALIDATE_COMPETITION,
  SET_VALIDATION_STEP,
  VALIDATE_STEP,
  VALIDATE_STEP_1,
  VALIDATE_STEP_2,
  VALIDATE_STEP_3,
  VALIDATE_STEP_4,
  VALIDATE_STEP_5,
} from '../actions/actions'

import { validateStep1,
  validateStep2,
  validateStep3,
  validateStep4,
  validateStep5 } from '../actions/actions'

const _ = require('lodash')

const INITIAL_STATE = {
  tab: '',
  criteriaTab: 'sales_criterias',
  pointMethod: 'sales',
  loading: false,
  error: null,
  show_region: false,
  models: [],
  admissionReasons: [],
  engineTypes: [],
  engines: [],

  regions: [],
  selectedRegions: new Set(),
  companies: [],
  selectedCompanies: new Set(),
  employees: [],
  selectedEmployees: new Set(),

  evaluationCriteraCompleted: false,

  salesModel: new Set(),
  salesAdmissionReasons: new Set(),
  salesEngineTypes: new Set(),
  salesEngines: new Set(),

  salesContractFrom: null,
  salesContractTo: null,
  salesAdmissionFrom: null,
  salesAdmissionTo: null,
  salesHubArrivalTo: null,
  salesHubArrivalFrom: null,

  pointOption: null,
  pointsPerContract: 0,
  pointsRange: [
    {from: '', to: '', points: ''}
  ],

  counter: false,
  counterPoints: 0,

  ranking: false,
  rankingType: 'ranking_overall',

  fromDate: null,
  toDate: null,
  name: '',
  description: '',
  teaserImage: '',
  saved: false,

  validated: false,
  validate_step: 1,
  validated_step_1: true,
  validated_step_2: false,
  validated_step_3: false,
  validated_step_4: false,
  validated_step_5: false
}

const userCompetitionReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case CHANGE_TAB: {
      return {
        ...state,
        tab: action.tab
      }
    }
    case SET_VALIDATION_STEP: {
      let step = state.validate_step < (action.step - 1) ? (action.step - 1) : state.validate_step
      return {
        ...state,
        validate_step: step
      }
    }
    case VALIDATE_STEP_1: {
      console.log("validate step 1")
      return state
    }
    case VALIDATE_STEP_2: {
      console.log("validate step 2")
      return validateStep2(state)
    }
    case VALIDATE_STEP_3: {
      console.log("validate step 3")
      return validateStep3(state)
    }
    case VALIDATE_STEP_4: {
      console.log("validate step 4")
      return validateStep4(state)
    }
    case VALIDATE_STEP_5: {
      console.log("validate step 5")
      return validateStep5(state)
    }
    case CHANGE_CRITERIA_TAB: {
      return {
        ...state,
        criteriaTab: action.criteriaTab
      }
    }
    case CHANGE_POINT_METHOD: {
      return {
        ...state,
        pointMethod: action.pointMethod
      }
    }
    case CHANGE_SALES_MODEL: {
      let label = action.salesModel
      let models = state.salesModel

      if (models.has(label)) {
        models.delete(label)
      } else {
        models.add(label)
      }

      return {...state, salesModel: models}
    }
    case CHANGE_SALES_ADMISSION_REASON: {
      let label = action.admissionReason
      let reasons = state.salesAdmissionReasons

      if (reasons.has(label)) {
        reasons.delete(label)
      } else {
        reasons.add(label)
      }

      return {...state, salesAdmissionReasons: reasons}
    }
    case CHANGE_SALES_ENGINE_TYPE: {
      let label = action.engineType
      let engineTypes = state.salesEngineTypes

      if (engineTypes.has(label)) {
        engineTypes.delete(label)
      } else {
        engineTypes.add(label)
      }

      return {...state, salesEngineTypes: engineTypes}
    }
    case CHANGE_SALES_ENGINE: {
      let label = action.engine
      let engines = state.salesEngines

      if (engines.has(label)) {
        engines.delete(label)
      } else {
        engines.add(label)
      }

      return {...state, salesEngines: engines}
    }
    case CHANGE_POINT_OPTION: {
      return {
        ...state,
        pointOption: action.pointOption
      }
    }
    case CHANGE_POINTS_PER_CONTRACT: {
      return {
        ...state,
        pointsPerContract: action.pointsPerContract
      }
    }
    case UPDATE_POINTS_RANGE: {
      let update = {...state.pointsRange[action.index], ...action.attr}
      let newPointsRange = state.pointsRange

      newPointsRange[action.index] = update

      return {
        ...state,
        pointsRange: newPointsRange
      }
    }
    case ADD_POINTS_RANGE: {
      let pointsRange = state.pointsRange
      let lastElement = _.last(state.pointsRange)
      pointsRange.push({from: (parseInt(lastElement.to) + 1), to: '', points: ''})
      return {
        ...state,
        pointsRange: pointsRange
      }
    }
    case CHANGE_COUNTER: {
      return {
        ...state,
        counter: action.counter
      }
    }
    case CHANGE_COUNTER_POINTS: {
      return {
        ...state,
        counterPoints: action.counterPoints
      }
    }
    case CHANGE_RANKING: {
      return {
        ...state,
        ranking: action.ranking
      }
    }
    case CHANGE_RANKING_TYPE: {
      return {
        ...state,
        rankingType: action.rankingType
      }
    }
    case TOGGLE_SELECTED_REGIONS: {
      let label = action.region
      let regions = state.selectedRegions

      if (regions.has(label)) {
        regions.delete(label)
      } else {
        regions.add(label)
      }

      state.employees.forEach(employee => {
        if (action.checked == true) {
          if (employee.region_id.includes(label.id) === true) {
            if (state.selectedEmployees.has(employee) === false) {
              state.selectedEmployees.add(employee)
            }
          }
        } else {
          if (employee.region_id.includes(label.id) === true) {
            if (state.selectedEmployees.has(employee) === true) {
              state.selectedEmployees.delete(employee)
            }
          }
        }
      })

      state.companies.forEach(company => {
        if (action.checked == true) {
          if (company.region_id.includes(label.id)) {
            if (state.selectedCompanies.has(company) === false) {
              state.selectedCompanies.add(company)
            }
          }
        } else {
          if (company.region_id.includes(label.id)) {
            if (state.selectedCompanies.has(company) === true) {
              state.selectedCompanies.delete(company)
            }
          }
        }
      })

      return {...state, selectedRegions: regions}
    }
    case TOGGLE_SELECTED_COMPANIES: {
      let label = action.company
      let companies = state.selectedCompanies

      if (companies.has(label)) {
        companies.delete(label)
      } else {
        companies.add(label)
      }

      state.employees.forEach(employee => {
        if (action.checked == true) {
          if (employee.company_id.includes(label.id) === true) {
            if (state.selectedEmployees.has(employee) === false) {
              state.selectedEmployees.add(employee)
            }
          }
        } else {
          if (employee.company_id.includes(label.id) === true) {
            if (state.selectedEmployees.has(employee) === true) {
              state.selectedEmployees.delete(employee)
            }
          }
        }
      })

      return {...state, selectedCompanies: companies}
    }
    case TOGGLE_SELECTED_EMPLOYEES: {
      let label = action.employee
      let employees = state.selectedEmployees

      if (employees.has(label)) {
        employees.delete(label)
      } else {
        employees.add(label)
      }

      return {...state, selectedEmployees: employees}
    }
    case CHANGE_EMPLOYEE_TARGET: {
      let employee = action.employee
      let points = action.points
      let selectedEmployees = state.selectedEmployees
      let employees = state.employees

      selectedEmployees.forEach(e => {
        if (e.id === employee.id) {
          e.points = points
        }
      })

      employees.forEach(e => {
        if (e.id === employee.id) {
          e.points = points
        }
      })

      return {
        ...state,
        employees: employees,
        selectedEmployees: selectedEmployees
      }
    }
    case CHANGE_SALES_CONTRACT_FROM: {
      return {...state, salesContractFrom: action.salesContractFrom}
    }
    case CHANGE_SALES_CONTRACT_TO: {
      return {...state, salesContractTo: action.salesContractTo}
    }
    case CHANGE_SALES_ADMISSION_FROM: {
      return {...state, salesAdmissionFrom: action.salesAdmissionFrom}
    }
    case CHANGE_SALES_ADMISSION_TO: {
      return {...state, salesAdmissionTo: action.salesAdmissionTo}
    }
    case CHANGE_SALES_HUB_ARRIVAL_FROM: {
      return {...state, salesHubArrivalFrom: action.salesHubArrivalFrom}
    }
    case CHANGE_SALES_HUB_ARRIVAL_TO: {
      return {...state, salesHubArrivalTo: action.salesHubArrivalTo}
    }
    case CHANGE_FROM_DATE: {
      return {...state, fromDate: action.fromDate}
    }
    case CHANGE_TO_DATE: {
      return {...state, toDate: action.toDate}
    }
    case CHANGE_NAME: {
      return {...state, name: action.name}
    }
    case CHANGE_DESCRIPTION: {
      return {...state, description: action.description}
    }
    case CHANGE_TEASER_IMAGE: {
      return {...state, teaserImage: action.teaserImage}
    }
    case FETCH_SALES_CONFIGURATION_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_SALES_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        loading: true,
        models: action.payload.configuration.models,
        admissionReasons: action.payload.configuration.admission_reasons,
        engineTypes: action.payload.configuration.engine_types,
        engines: action.payload.configuration.engines
      }
    }
    case FETCH_SALES_CONFIGURATION_FAILURE: {
      return {
        ...state,
        loading: true
      }
    }
    case FETCH_HIERARCHY_CONFIGURATION_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_HIERARCHY_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        loading: true,
        show_region: action.payload.configuration.show_region,
        regions: action.payload.configuration.regions,
        selectedRegions: new Set(action.payload.configuration.regions),
        companies: action.payload.configuration.companies,
        selectedCompanies: new Set(action.payload.configuration.companies),
        employees: action.payload.configuration.employees,
        selectedEmployees: new Set(action.payload.configuration.employees)
      }
    }
    case FETCH_HIERARCHY_CONFIGURATION_FAILURE: {
      return {
        ...state,
        loading: true
      }
    }
    case SAVE_USER_COMPETITION: {
      console.log("SAVE USER COMPETITION")
      fetch("/api/v1/user_competitions_configuration/save.json", {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          information: {
            name: state.name,
            from: state.fromDate.format('DD.MM.YYYY'),
            to: state.toDate.format('DD.MM.YYYY'),
            description: state.description,
            teaserImage: state.teaserImage
          },
          configuration: {
            target_group_section: 'employees',
            point_method: state.pointMethod,
            points_per_contract: state.pointsPerContract,
            points_interval: state.pointsRange,
            points_budget: state.counterPoints,
            point_option: state.pointOption,
            selected_ranking_type: state.rankingType,
            ranking_activated: (state.ranking ? 'ranking_true' : 'ranking_false'),
            counter_activated: (state.counter ? 'counter_true' : 'counter_false')
          },
          criterias: {
            ldg: {
              models: Array.from(state.salesModel),
              admission_reasons: Array.from(state.salesAdmissionReasons),
              engine_types: Array.from(state.salesEngineTypes),
              engines: Array.from(state.salesEngines)
            },
            date_ranges: {
              contract_dates: {from: state.salesContractFrom, to: state.salesContractTo},
              admission_dates: {from: state.salesAdmissionFrom, to: state.salesAdmissionTo},
              hub_arrival_dates: {from: state.salesHubArrivalFrom, to: state.salesHubArrivalTo}
            },
            individual_targets: _.map(Array.from(state.selectedEmployees), function(u) { return {id: u.id, points: u.points} })
          },
          target_data: {
            employees: _.map(Array.from(state.selectedEmployees), 'id')
          }
        })
      })

      return {...state, saved: true}
    }

    default: return state
  }
}

export default userCompetitionReducer;
